import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { useCollectionStore } from "~/store/useCollectionStore.js";
import { collectionApiService } from "~/services/collectionApiService";

export const useCollectionApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const { updateCollectionList } = useCollectionStore();

  const { fetchCollections, pinOrUnpinCollection, deleteCollection } =
    collectionApiService();

  // Common logic for API calls
  const handleApiCall = async (apiFunction, body, callback, notify) => {
    loading.value = true;
    data.value = null;
    updateCollectionList(null);

    try {
      const response = await apiFunction(body);
      const message =
        response.data.data?.message || "An unknown error occurred.";
      if (response?.status === 200) {
        data.value = response.data?.data;
        updateCollectionList(response.data?.data);
        if (callback) callback();
        if (notify) notifySuccess(message);
      } else {
        notifyError({
          title: response.data?.message || "An unknown error occurred.",
        });
      }
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const fetchCollectionsApi = async () => {
    handleApiCall(fetchCollections, null, null, false);
  };

  const pinOrUnpinCollectionApi = async ({ collectionId, callback }) => {
    const body = { id: collectionId };
    handleApiCall(pinOrUnpinCollection, body, callback, true);
  };

  const deleteCollectionApi = ({ collectionId, callback }) => {
    const body = { id: collectionId };
    handleApiCall(deleteCollection, body, callback, true);
  };

  return {
    data,
    error,
    loading,
    fetchCollectionsApi,
    deleteCollectionApi,
    pinOrUnpinCollectionApi,
  };
};
